<template>
  <div class="invite">
    <div class="invitation-item-list">
      <div class="invitation-item" v-for="(item, index) in rules" :key="index"
           :class="{'active': item.num <= invitationList.length}">
        <div class="invitation-item-inner">
          <span class="discount">
            <b>{{100 - item.discount * 10}}</b>
            % off
          </span>
          <p>{{$t('invitation.labelConfigExpires')}}</p>
        </div>
        <p class="invitation-rule">
          {{$t('invitation.labelConfigAmount', {num: item.num})}}
        </p>
      </div>
    </div>
    <div class="invitation-current">
      <div class="text-center">
        <p>
          <template v-if="invitationList.length >= 1 && invitationList.length < 10">
            {{$t('invitation.labelConfigMore', {friend: invitationList.length, num: invitationTip.num - invitationList.length, discount: invitationTip.discount})}}
          </template>
          <template v-if="invitationList.length >= 10">
            {{$t('invitation.labelEnough', {num: invitationList.length})}}
          </template>
          <template v-if="!invitationList.length">
            {{$t('invitation.tipEmptyInvitation')}}
          </template>
        </p>
        <p v-if="dataLoaded" class="friend-avatars">
          <img :src="item.avatar" alt="" v-for="(item, index) in invitationList" :key="index" />
        </p>
        <template v-if="invitationList.length < 10">
          <button class="btn primary" @click="FBInvite(user.uid)" v-if="!isILife">
            {{$t('invitation.buttonInvitation')}}
          </button>
          <button class="btn default" v-copy="invitationLink">{{$t('invitation.buttonCopy')}}</button>
        </template>
        <template v-else>
          <router-link :to="{name: 'shelf'}">
            <button class="btn primary">
              {{$t('invitation.buttonPay')}}
            </button>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api/'
import { mapGetters } from 'vuex'
import FBShare from 'src/mixins/FBShare'
export default {
  name: 'invitation',
  mixins: [FBShare],
  data () {
    return {
      rules: [
        { num: 1, discount: 9 },
        { num: 3, discount: 8 },
        { num: 6, discount: 7 },
        { num: 10, discount: 6 }
      ],
      invitationList: [],
      invitationTip: {
        discount: 9,
        num: 12
      },
      dataLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    isILife () {
      return window.location.hostname.includes('ilifediary')
    },
    invitationLink () {
      return `https://ipastbook.com?sourceId=${this.user.uid}`
    }
  },
  created () {
    this.fetchReferral()
  },
  methods: {
    fetchReferral () {
      return api.fetchReferral().then(data => {
        this.dataLoaded = true
        if (!data || data.errors) {
          return
        }
        const friendList = []
        Object.keys(data.childrenUids).map(item => {
          friendList.push(Object.assign({ uid: item }, data.childrenUids[item]))
        })
        this.invitationList = friendList
        this.invitationTip = this.handleInvitation(this.invitationList.length)
      })
    },
    handleInvitation (num) {
      let invitationTip = {}
      let flag = true
      for (const item of this.rules) {
        if (item.num > num && flag) {
          invitationTip = item
          flag = false
        }
      }
      return invitationTip
    }
  }
}
</script>

<style lang="scss">

  .invite {
    position: relative;
    padding: 1rem;
    padding-bottom: 2rem;
    max-width: 680px;
    margin: 0 auto;
    p {
      margin: 0;
    }
    .invitation-item-list {
      overflow: hidden;
      .invitation-item {
        width: 50%;
        float: left;
        text-align: center;
        border: 1px solid #eeeeee;
        padding: 16px;
        font-size: 14px;
      }
      .invitation-item.active {
        .invitation-rule {
          color: #333;
        }
        .invitation-item-inner {
          background: $el-color-primary;
          background: linear-gradient(180deg, rgba(251,156,161,1) 0%, rgba(245,99,104,1) 100%);
        }
      }
      .invitation-item-inner {
        position: relative;
        background: {
          color: #d5d5d5;
          repeat: no-repeat;
          size: 100% 100%;
        };
        color: white;
        height: 80px;
        border-radius: 6px;
      }
      .invitation-rule {
        margin-top: 10px;
        color: #999;
      }
    }
    .invitation-item-inner {
      line-height: 80px;
      .discount {
        font-size: 18px;
        b {
          font-size: 34px;
        }
      }
      p {
        line-height: 1;
        position: absolute;
        bottom: 6px;
        right: 8px;
        font-size: 12px;
        margin: 0;
      }
    }
    .invitation-current {
      p {
        margin: 1rem 0;
      }
    }
    .friend-avatars {
      img {
        display: inline-block;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 2px solid white;
        background-color: #eeeeee;
      }
      img + img {
        margin-left: -8px;
      }
    }
  }
</style>
